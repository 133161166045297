.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh; }

.dashboard-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
